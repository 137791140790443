<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'TagList',
              }"
            >{{ "標籤管理" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'TagList',
              }"
            >{{ "標籤列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
            >{{
                isReadOnly ? "查看標籤" : isEditing ? "編輯標籤" : "新增標籤"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看標籤" : isEditing ? "編輯標籤" : "新增標籤"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*標籤"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.tag.name.$error ? false : null"
                    v-model="tag.name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.tag.name.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="外部代碼"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="tag.outer_code"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>


                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="會員(數量)"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="tag.customers_count"
                    :disabled=true
                  ></b-form-input>
                </b-form-group>

              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
              >儲存
              </b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import tagApi from "../../../apis/tag";

export default {
  setup: () => ({v$: useVuelidate()}),

  validations() {
    return {
      tag: {
        name: {required},
      }
    };
  },

  data() {
    return {
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      tag: {
        name: null,
        outer_code: null,
        customers_count: 0,
      },
      showLoadingUpload: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    isReadOnly() {
      return this.$route.name === "TagView";
    },
  },
  mounted() {
    if (!this.$route.params.tag_id) {
      this.clearTag();
      this.isEditing = false;
    } else {
      this.fetchTag();
      this.isEditing = true;
    }
  },
  methods: {
    async fetchTag() {
      this.showLoading = true;
      const response = await tagApi.getTag(
        this.organization,
        this.$route.params.tag_id
      );
      this.tag = response.data.data;
      this.showLoading = false;
    },

    clearTag() {
      this.tag = {
        tag: null,
        outer_code: null,
        customers_count: 0,
      };
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.isEditing) {
          let response = await tagApi.updateTag(
            this.organization,
            this.tag
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({name: "TagList"});
          }
        } else {
          let response = await tagApi.storeTag(this.tag);

          if (response.status && response.status >= 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({name: "TagList"});
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
          return
        }

        if (error.response.status === 409) {
          this.$swal.fire({title: "此標籤名稱已經存在，無法新增", type: "error",});
          return
        }

        this.$swal.fire({title: this.isEditing ? "更新失敗" : "新增失敗", type: "error",});
      }
    },
    handleCancel() {
      this.$router.push({name: "TagList"});
    }
  },
};
</script>
